<template>
  <v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >¿Seguro que quieres eliminar este gen?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteKBGene"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-btn
        @click="$router.push({ name: 'KBGenes' })"
        class="mx-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="geneId != 'nuevo'"
        @click="
          $router.push({
            name: 'EPGGeneDetail',
            params: { geneId }
          })
        "
        class="mx-2"
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark>
          mdi-book
        </v-icon>
      </v-btn>
      <v-btn @click="submit" class="mx-2" fab dark small color="green">
        <v-icon dark>
          mdi-content-save
        </v-icon>
      </v-btn>
      <v-btn
        @click="dialogDelete = true"
        v-if="geneId != 'nuevo'"
        class="mx-2"
        fab
        dark
        small
        color="red"
      >
        <v-icon dark>
          mdi-delete
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12 px-5" sm="12" md="6">
              <v-card>
                <v-text-field
                  v-model="kbData.symbol"
                  :rules="validationRules.slugRules"
                  label="Símbolo"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="kbData.omim"
                  :rules="validationRules.slugRules"
                  label="OMIM"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="kbData.locus"
                  :rules="validationRules.slugRules"
                  label="Locus"
                  required
                ></v-text-field>
              </v-card>
              <v-card>
                <v-card-title>
                  Nombres principales
                </v-card-title>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.en"
                      label="Inglés"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.es"
                      label="Español"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="kbData.main_names.po"
                      label="Portugués"
                      required
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card>
              <v-card>
                <v-switch v-model="kbData.has_epg" label="Tiene EPG"></v-switch>
                <v-switch
                  v-model="kbData.has_card"
                  label="Tiene Tarjeta"
                ></v-switch>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <MultipleInput
                label="Nombres alternativos"
                v-model="kbData.alternative_names"
                textField="name"
              />

              <MultipleInput
                label="Símbolos alternativos"
                v-model="kbData.alternative_symbols"
                textField="symbol"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import MultipleInput from "@/components/mylogy/MultipleInput.vue";
export default {
  props: ["geneId"],
  components: { MultipleInput },
  data() {
    return {
      valid: false,
      dialogDelete: false,
      validationRules: {
        slugRules: [v => !!v || "El campo ID es obligatorio"]
      },
      kbData: {
        alternative_names: [],
        alternative_symbols: [],
        symbol: null,
        omim: null,
        locus: null,
        has_epg: null,
        has_card: false,
        id: null,
        induces: [],
        inhibits: [],
        kb_inductions: [],
        kb_inhibitios: [],
        kb_relationships: [],
        kb_subs: [],
        main_names: {
          en: null,
          es: null,
          po: null
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let self = this;
      if (self.geneId != "nuevo") {
        Strapi.get(`/kb-genes/${this.geneId}`).then(res => {
          self.kbData = res.data;
        });
      }
    },
    submit() {
      if (this.geneId == "nuevo") {
        this.createKBGene();
      } else {
        this.updateKBGene();
      }
    },
    updateKBGene() {
      let self = this;
      Strapi.put(`/kb-genes/${self.geneId}`, self.kbData).then(res => {
        self.kbData = res.data;
        self.loadData();

        fetch(
          `${process.env.VUE_APP_APPBASE_DOMAIN}/${process.env.VUE_APP_GENES_CLUSTER}/_update/${res.data.id}`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
              doc: {
                Gene_Name_ES: self.kbData.main_names.es,

                Symbol: self.kbData.symbol,
                Gene_Name_EN: self.kbData.main_names.en,
                Alternative_Names: self.kbData.alternative_names.map(
                  alternativeName => alternativeName.name
                ),
                Has_EPG: self.kbData.has_epg,
                Gene_Name_PO: self.kbData.main_names.po,

                OMIM: self.kbData.omim,
                Locus: self.kbData.locus,
                Alternative_Symbols: self.kbData.alternative_symbols.map(
                  alternativeSymbol => alternativeSymbol.symbol
                ),
                id: res.data.id,
                Has_Card: self.kbData.has_card
              }
            }),
            headers: {
              Authorization: `Basic ${window.btoa(
                process.env.VUE_APP_APPBASE_CRED
              )}`,
              "Content-Type": "application/json"
            }
          }
        )
          .then(response => response.json())
          .then(() => {
            self.loadData();
          });
      });
    },
    createKBGene() {
      let self = this;
      Strapi.post(`/kb-genes`, this.kbData).then(res => {
        self.kbData = res.data;

        fetch(
          `${process.env.VUE_APP_APPBASE_DOMAIN}/${process.env.VUE_APP_GENES_CLUSTER}/_doc/${res.data.id}`,
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
              Gene_Name_ES: self.kbData.main_names.es,
              Panels: [],
              Symbol: self.kbData.symbol,
              Gene_Name_EN: self.kbData.main_names.en,
              Alternative_Names: self.kbData.alternative_names.map(
                alternativeName => alternativeName.name
              ),
              Has_EPG: self.kbData.has_epg,
              Gene_Name_PO: self.kbData.main_names.po,
              Dis_PO: [],
              OMIM: self.kbData.omim,
              Locus: self.kbData.locus,
              Alternative_Symbols: self.kbData.alternative_symbols.map(
                alternativeSymbol => alternativeSymbol.symbol
              ),
              Dis_ES: [],
              id: res.data.id,
              Dis_EN: [],
              Has_Card: self.kbData.has_card
            }),
            headers: {
              Authorization: `Basic ${window.btoa(
                process.env.VUE_APP_APPBASE_CRED
              )}`,
              "Content-Type": "application/json"
            }
          }
        )
          .then(response => response.json())
          .then(data => {
            self.$router.push({
              name: "KBGeneDetail",
              params: { geneId: data._id }
            });
          });
      });
    },
    deleteKBGene() {
      let self = this;
      self.dialogDelete = false;
      Strapi.delete(`/kb-genes/${self.geneId}`).then(res => {
        fetch(
          `${process.env.VUE_APP_APPBASE_DOMAIN}/${process.env.VUE_APP_GENES_CLUSTER}/_doc/${res.data.id}`,
          {
            method: "DELETE",
            mode: "cors",
            headers: {
              Authorization: `Basic ${window.btoa(
                process.env.VUE_APP_APPBASE_CRED
              )}`,
              "Content-Type": "application/json"
            }
          }
        )
          .then(response => response.json())
          .then(() => {
            self.$router.push({ name: "KBGenes" });
          });
      });
    }
  }
};
</script>
<style scoped></style>
